html {
    height: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    /* font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; */
    background-color: #efebe9 !important;
    height: 100%;
    margin: 0;
}

html, body {
    touch-action: manipulation; /* This can prevent double-tap zoom in some cases */
    user-scalable: no; /* Ensure user-scalable is set to no */
}

div#root {
    height: 100%;
    /* border: solid; */
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

button {
    text-transform: none !important;
}