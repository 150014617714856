.profile-container {
    background-color: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    margin: auto;
    padding: 10px;
    font-size: 17px;
    max-width: 784px;
    margin-top: 50px;
    min-height: 700px;
    position: relative;
}

.profile-container-name {
    display: flex;
}

.profile-image-input {
    position: absolute;
    bottom: 0;
    outline: none;
    color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 120px;
    cursor: pointer;
    transition: 0.5s;
    height: 150px;
    border-radius: 0 0 183px 183px;
    border: solid
}


.profile-image-input-icon-wrapper {
    position: absolute;
    bottom: 0;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 120px;
    cursor: pointer;
    transition: 0.5s;
    background: rgba(0, 0, 0, 0.4);
    height: 150px;
    border-radius: 0 0 183px 183px;
    margin-top: 150px;
}

.profile-image-input-icon {
    width: 100%;
    color: white;
    font-size: 50px !important;
    margin-left: 5px;
    margin-top: 27px;

}

.profile-image-input::-webkit-file-upload-button {
    visibility: hidden;
}

.profile-image-preview {
    position: absolute;
    height: 300px;
    width: 300px;
    border-radius: 50%;
}

.profile-text-field {
    margin: 10px 0 0 13px;
    border-radius: 6px;
    width: calc(100% - 61px);
    border: 1px solid #dddfe2;
    color: #1d2129;
    font-family: Helvetica, Arial, sans-serif;
    height: 21px;
    line-height: 16px;
    padding: 14px 16px;
    vertical-align: middle;
    height: 66px !important;
}

.profile-save {
    margin: 15px 0 0 15px;
}

.profile-default-wrapper {
    width: 300px;
    height: 300px;
    border: solid;
    border-radius: 50%;
    background: lightgrey;
    margin-left: -3px;
    margin-top: -3px;
}

.profile-default {
    margin-left: -31px;
    position: relative;
    font-size: 356px !important;
    color: white;
    /* border: solid; */
    margin-top: -31px;
}

.profile-upload {
    position: relative;
    /* max-width: 205px; */
    /* margin: 50px 0; */
    justify-content: center;
    align-items: center;
    /* border: solid green; */
    width: 32%;
    height: 300px;
}

/* .profile-upload .profile-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
    display: flex;
} */

.profile-upload  input {
    display: none;
}



/* .profile-upload .profile-preview {
    width: 192px;
    height: 240px;
    position: relative;
    border-radius: 10px;
    border: 3px dotted #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
} */

/* .profile-upload .profile-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

} */

.profile-edit {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
    /* border: solid; */
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
}

.profile-edit-label {
    cursor: pointer;
    z-index: 10;
    /* border: solid; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #0D47A1;
    font-weight: 500;
    text-align: center;
}

.profile-edit-icon {
    color: grey;
    margin-left: 4px;
    margin-top: 4px;
    font-size: 60px;
}

.login-date-birth {
    padding: 6px 0 0 12px;
}

.login-date-birth-select {
    /* margin-left: 10px; */
}

.login-date-birth-select > select {
    padding: 10px 28px 10px 10px;
}

select {
    padding: 10px 28px 10px 10px;
    margin-right: 10px;
}

.profile-country-region {
    margin: 15px 0 0 10px;
    display: -ms-flexbox;
    -ms-flex-align: center;
}

select.profile-country {
    /* margin-left: 10px; */
}

.profile-text-gender-wrapper {
    display: flex;
    margin: 10px 0 0 10px;
}

@media only screen and (max-width: 728px) {
    .profile-upload {
        height: 200px;
    }
}

@media only screen and (max-width: 505px) {
    .profile-upload {
        height: 130px;
    }
}

