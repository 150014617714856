.common-country {
    width: 100%;
    height: 77px;
    align-items: center;
    margin-bottom: 18px;
}

.common-country-title {
    margin-right: 5px;
    margin-bottom: 10px;
    color: #172b4d;
    font-weight: 500;
}

.common-country-text {
    margin-right: 5px;
    margin-bottom: 10px;
    color: #172b4d;
    font-weight: 500;
}

.common-country-text:hover {
    background-color: #E8E8E8;
}

.common-country-field {
    width: 335px;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #172b4d;
    padding-left: 14px;
    color: #172b4d;
}

.common-country-field:focus {
    outline: none;
}

/* @media only screen and (max-width: 728px) {
    .common-country-text {
        width: 300px;
    }

    country.common-country-field {
        width: 300px;
    }
} */
